import { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  Card,
} from "@mui/material";
import Toast from "../../utils/Toast";
import LoadingCircle from "../../utils/LoadingCircle";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import "react-calendar/dist/Calendar.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import { getFormattedDateTime } from "../../utils/getFormattedDateTime";
import ReservDeskList from "../components/ReservDeskList";

const mainApi = new HttpMainApi();
interface propsType {
  userState: userState;
}
interface ReservationDetail {
  deviceNum: string;
  reservTime: string;
  reservDt: string;
  userName: string;
  userId: string;
  inputDt: string;
}
interface Reservation {
  sk: string;
  pk: string;
  reservations: { [key: string]: ReservationDetail };
}

const ReservationLocker = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const health = [];
  for (let i = 1; i <= 84; i++) {
    const lockerNumber = i.toString().padStart(2, "0");
    health.push(`H${lockerNumber}`);
  }
  const golf = [];
  for (let i = 1; i <= 18; i++) {
    const lockerNumber = i.toString().padStart(2, "0");
    golf.push(`G${lockerNumber}`);
  }

  const buttons = [
    { key: "헬스장락커", label: "헬스장 락커" },
    { key: "골프연습장락커", label: "골프연습장 락커" },
  ];
  const endlines = [
    () => `1) 모바일 신청은 전월 25일 부터 전월 말일까지 입니다.`,
    () =>
      `   - ${displayMonthName(
        months[1]
      )} 1일 부터 모바일 신청은 마감되었습니다.`,
    () =>
      `2) ${displayMonthName(
        months[1]
      )} 추가 신청은 관리소에서 진행하고 있습니다.`,
    () =>
      `3) ${displayMonthName(months[2])} 신청은 ${displayMonthName(
        months[1]
      )} 25일 오픈합니다.`,
    () =>
      `  - ${displayMonthName(
        months[1]
      )} 25일 00시00분 ~ ${formatLastDayOfMonth(useDate)} 23시59분`,
    () => `4) ${months[2]} 1일 이후 취소시 한 달 이용료가 청구됩니다.`,
  ];
  const [selectedGubun, setSelectedGubun] = useState(buttons[0].key);
  const [selectedDesk, setSelectedDesk] = useState(null);
  const [selectName, setSelectName] = useState("골프장");
  const [reservOn, setReservOn] = useState(true);

  const handleButtonClick = (buttonKey: any) => {
    setSelectedDesk(null);
    setSelectedGubun(buttonKey);
  };
  const handleDeskButtonClick = (time: any) => {
    setSelectedDesk(time);
  };
  function formatYearMonth(selectYearMonth: string) {
    const regex = /(\d{4})년 (\d{1,2})월/;
    const matches = selectYearMonth.match(regex);
    if (matches) {
      const year = matches[1];
      const month = matches[2].padStart(2, "0");
      return `${year}${month}`;
    }

    return ""; // 매칭되지 않는 경우 빈 문자열 반환
  }

  const [golfMember, setGolfMember] = useState(true);

  const get_my_reservation = async (selectedGubun: string) => {
    setIsLoading(true);

    let gubunFilter = "";
    if (selectedGubun === "골프연습장락커") {
      gubunFilter = "GOLF";
      setSelectName("골프장");
    } else {
      gubunFilter = "HEALTH";
      setSelectName("헬스장");
    }

    const formatted = formatYearMonth(selectYearMonth);
    const userId = props.userState.userId;
    const pk = userId;
    const sk = formatted;
    const param: any = {
      pk: pk,
      sk: sk,
      reservState: "신청",
      reservFilter: `${formatted}/${gubunFilter}`,
    };

    const res = await mainApi.get_my_reservation_diff_check(param);
    console.log("res.code diff  : ", res.code);
    if (res.code === "200") {
      setGolfMember(true);
    } else if (res.code === "400") {
      setGolfMember(false);
    }
    setIsLoading(false);
  };

  const reservation_save = async () => {
    if (golfMember === false) {
      toastRef.current?.toast(
        `[회원전용]${selectName} 신청 후 예약이 가능합니다.`,
        "error",
        4000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      return;
    }

    let formatted = formatYearMonth(selectYearMonth);

    if (!formatted) {
      const today = new Date();
      today.setDate(1); // 🔥 1일로 고정하여 날짜가 밀리는 문제 방지
      today.setMonth(today.getMonth() + 1); // 다음 달로 변경

      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      formatted = `${year}${month}`;
    }
    const { formattedDateTime, formatdaypk } = getFormattedDateTime();
    const userId = props.userState.userId;
    const userAddr = props.userState.readAddress;
    const userName = props.userState.userName;
    const pk = `${formatted}/LOCKER`;
    // console.log(" pk : ", pk);
    // console.log(" formatted : ", formatted);
    const pkRegex = /^\d{6}\/LOCKER$/; // "YYYYMM/GOLF" 형식 확인
    if (!pkRegex.test(pk)) {
      alert(
        "죄송합니다. 저장하는 중 오류가 발생했습니다.\n새로고침 후 다시한번 클릭해주세요."
      );
      return;
    }

    let communityPrice = 0;
    if (selectedGubun === "골프연습장락커") {
      communityPrice = communityGPrice;
    } else {
      communityPrice = communityHPrice;
    }
    const param: any = {
      pk: pk,
      sk: selectedGubun,
      option: selectedDesk,
      userId: userId,
      userAddr: userAddr,
      userName: userName,
      inputDt: formattedDateTime,
      reservDt: selectYearMonth,
      my_pk: pk,
      my_sk: `${formatted}${formatdaypk}`,
      communityPrice: communityPrice,
    };
    const res = await mainApi.put_reserv_month(param);
    if (res.code === "200") {
      //락커 관리화면 업데이트
      let gubunFilter = "";
      if (selectedGubun === "골프연습장락커") {
        gubunFilter = "GOLF";
      } else {
        gubunFilter = "HEALTH";
      }
      const param: any = {
        pk: `LOCKER/${gubunFilter}`,
        sk: selectedDesk,
        lockerInputDt: formattedDateTime,
        userId: userId,
        readAddress: userAddr,
        userName: userName,
      };
      const res = await mainApi.update_locker_user(param);
      if (res.code === "200") {
        navigate("/reservationlockerend");
      }
    } else if (res.code === "601") {
      setAlertOpen(false);
      alert("이미 예약된 번호입니다.\n다시 조회 후 진행해주세요.");
    } else if (res.code === "600") {
      setAlertOpen(false);
      alert("[1인 1락커] 이미 예약된 락커가 있습니다.");
    } else {
      setAlertOpen(false);
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const [times, setTimes] = useState<string[]>([]);
  const extractReservationTimes = (
    reservationData: Reservation[]
  ): string[] => {
    const times: string[] = [];

    reservationData.forEach((item) => {
      Object.keys(item.reservations).forEach((time) => {
        times.push(time);
      });
    });
    return times;
  };

  const get_day_reservation = async () => {
    setIsLoading(true);
    const formatted = formatYearMonth(selectYearMonth);
    const pk = `${formatted}/LOCKER`;
    const sk = selectedGubun;
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_day_reservation(param);
    if (res.code === "200") {
      const extractedTimes = extractReservationTimes(res.response.result);
      setTimes(extractedTimes);
    } else if (res.code === "400") {
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
    setIsLoading(false);
  };

  const [months, setMonths] = useState<string[]>([]);
  const [selectYearMonth, setSelectYearMonth] = useState<string>("");
  const [useDate, setUseDate] = useState("");
  const [selectedMonthGubun, setSelectedMonthGubun] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    // 현재 월과 다음 두 달을 계산합니다.
    const monthsArray = [
      new Date(currentYear, currentMonth - 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
      new Date(currentYear, currentMonth + 1, 1).toLocaleDateString("default", {
        year: "numeric",
        month: "long",
      }),
    ];

    setMonths(monthsArray);
    setSelectYearMonth(monthsArray[2]);
  }, []);

  const monthButtonClick = (monthName: string, index: number) => {
    const selectedYearMonth = monthName;
    setSelectYearMonth(selectedYearMonth);
    setSelectedMonthGubun(index);
    setSelectedDesk(null);
  };

  const displayMonthName = (monthName: string) => {
    return monthName?.substring(6);
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 2).toString().padStart(2, "0");
    setUseDate(year + month);
  }, []);

  useEffect(() => {
    get_day_reservation();
    get_my_reservation(selectedGubun);
  }, [selectedGubun, selectYearMonth]);

  const [alertOpen, setAlertOpen] = useState(false);
  const handleClickOpen = () => {
    if (
      selectedGubun === "" ||
      selectedGubun === undefined ||
      selectedGubun === null
    ) {
      toastRef.current?.toast("락커 위치를 선택하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    if (
      selectedDesk === "" ||
      selectedDesk === undefined ||
      selectedDesk === null
    ) {
      toastRef.current?.toast("락커번호를 선택하세요.", "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    setAlertOpen(true);
  };
  const handleClose = () => {
    setAlertOpen(false);
  };

  const [communityHPrice, setCommunityHPrice] = useState(0);
  const [communityGPrice, setCommunitGPrice] = useState(0);
  const [communityPrice, setCommunitPrice] = useState(3000);
  const get_h_price = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "헬스장락커";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setCommunityHPrice(res.response.result[0]["PRICE"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };
  const get_g_price = async () => {
    const pk = "COMMUNITY/ACCOUNT";
    const sk = "골프연습장락커";
    const param: any = {
      pk: pk,
      sk: sk,
    };
    const res = await mainApi.get_price(param);
    if (res.code === "200") {
      setCommunitGPrice(res.response.result[0]["PRICE"]);
    } else {
      alert(
        `알수 없는 에러가 발생했습니다.\n- CODE : ${res.code}\n- message : ${res.response.error_msg}`
      );
    }
  };

  const diffDay = ["25", "26", "27", "28", "29", "30", "31"];
  useEffect(() => {
    get_h_price();
    get_g_price();
    const { formatday } = getFormattedDateTime();
    if (diffDay.includes(formatday)) {
      setReservOn(true);
    } else {
      setReservOn(false);
    }
  }, []);

  useEffect(() => {
    if (communityGPrice > 0) {
      if (selectedGubun === "골프연습장락커") {
        setCommunitPrice(communityGPrice);
      } else {
        setCommunitPrice(communityHPrice);
      }
    }
  }, [selectedGubun]);

  function formatLastDayOfMonth(detailsReservDt: string): string {
    // 입력된 detailsReservDt에서 연도와 월을 추출
    const year = detailsReservDt.substring(0, 4);
    const month = detailsReservDt.substring(4, 6);

    // Date 객체를 생성, 다음 달의 첫 날을 나타내도록 설정
    // 주의: JavaScript의 Date는 월을 0부터 시작으로 취급하기 때문에 month에서 1을 빼주지 않습니다.
    const date = new Date(Number(year), Number(month) - 1, 0);
    // Date 객체를 사용하여 전달의 마지막 날짜를 구하고, 원하는 포맷으로 변환
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}월 ${String(date.getDate()).padStart(2, "0")}일 `;
    return formattedDate;
  }
  return (
    <>
      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          maxWidth: isMobile ? "100%" : "40rem",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 300,
              textAlign: "left",
              margin: "auto",
              mt: 2,
              ml: 3,
            }}
          ></Typography>
        </Box>

        <hr
          style={{ borderColor: "#ddd", borderWidth: "0.3px", width: "100%" }}
        />
        {reservOn ? (
          <>
            <Box
              sx={{
                width: "90%",
                maxWidth: isMobile ? "100%" : "40rem",
                margin: "auto",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{ color: "#0000FF" }}
                >
                  {displayMonthName(months[2])} 모바일 신청 기간입니다.
                </Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Typography
                  gutterBottom
                  component="div"
                  style={{ color: "#ffff0FF" }}
                >
                  ({displayMonthName(months[2])} 1일 이후는 관리소 방문 후
                  신청하셔야 합니다.)
                </Typography>
              </Stack>
            </Box>

            <ButtonGroup size="large" aria-label="large button group">
              {buttons.map((button) => (
                <Button
                  key={button.key}
                  style={{
                    borderColor: "#2E3341",
                    color: selectedGubun === button.key ? "#ffffff" : "#2E3341",
                    backgroundColor:
                      selectedGubun === button.key ? "#2E3341" : "",
                  }}
                  onClick={() => handleButtonClick(button.key)}
                >
                  {button.label}
                </Button>
              ))}
            </ButtonGroup>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 300,
                  textAlign: "left",
                  margin: "auto",
                  ml: 3,
                }}
              >
                락커번호
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ReservDeskList
                userState={props.userState}
                reserv_desk={
                  selectedGubun === "헬스장락커"
                    ? health
                    : selectedGubun === "골프연습장락커"
                    ? golf
                    : []
                }
                handleDeskButtonClick={handleDeskButtonClick}
                selectedDesk={selectedDesk}
                chunkNum={4}
                times={times}
              />
            </Box>

            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                justifyContent: "center",
                width: "95%", // 전체 너비를 사용하도록 설정
                height: "4em",
                marginTop: "3em",
              }}
            >
              <Button
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #ccc",
                  width: "100%",
                }}
                color="primary"
                variant="contained"
                endIcon={<CheckIcon />}
                onClick={() => handleClickOpen()}
              >
                <Typography variant="h6">
                  {displayMonthName(months[2])} 신청 하기
                </Typography>
              </Button>
            </Stack>
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontWeight: 300,
                  textAlign: "left",
                  margin: "auto",
                  ml: 3,
                }}
              >
                {/* 유의사항 */}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "95%",
              }}
            >
              <Card
                sx={{
                  minWidth: "340px",
                  borderRadius: "16px",
                  boxShadow: "none",
                  borderColor: "#f0f0f0",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  marginBottom: 2,
                }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  style={{
                    marginTop: "0.6em",
                  }}
                >
                  {displayMonthName(months[1])} 모바일 신청이 마감되었습니다.
                </Typography>
                <hr
                  style={{
                    borderColor: "#ddd",
                    borderWidth: "0.3px",
                    width: "95%",
                  }}
                />
                {endlines.map((guide, index) => (
                  <div style={{ textAlign: "left", width: "100%" }} key={index}>
                    <Typography
                      key={index}
                      style={{ margin: "20px", textAlign: "left" }}
                    >
                      {guide()}
                    </Typography>
                  </div>
                ))}
              </Card>
            </Box>

            <Stack
              direction="row"
              sx={{
                flexWrap: "wrap",
                justifyContent: "center",
                width: "95%", // 전체 너비를 사용하도록 설정
                height: "4em",
                marginTop: "3em",
              }}
            ></Stack>
          </>
        )}
      </Stack>
      <Dialog
        open={alertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: "500px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"신청 하시겠습니까?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            [락커 신청 정보]
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            신청자 : {props.userState.userName}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            사용 기간 : {selectYearMonth}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            락커 번호 : [{selectedGubun}] {selectedDesk}
          </DialogContentText>
          이용료 : {communityPrice}원
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>취소</Button>
          <Button onClick={reservation_save} autoFocus>
            신청
          </Button>
        </DialogActions>
      </Dialog>
      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default ReservationLocker;
